import { debounce, defer, isString } from 'lodash';
import React from 'react';
import cn from 'classnames';

import { BBCommonProps, BBUiConfig, EditorMode } from 'types/story';
import cms from 'common/utils/cms';
import { isLayerType } from 'common/utils/blocks/is-layer-type';
import { triggerFocus } from 'common/utils/helpers';
import { COMPONENT_STATES } from 'common/constants';
import EditIcon from 'common/components/Icon/Edit';
import CheckIcon from 'common/components/Icon/CheckMark';
import { SelectionToolbarPortal } from 'client/components/common/SelectionHint/Toolbar';
import { UNIFORM_PROPS } from 'common/constants/component-props';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import { CHILDREN_KEY } from 'client/components/common/BuildingBlocks/utils/common';
import ConditionalFeatureRender from 'client/components/common/ConditionalFeatureRender';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { FIELD_EDITABLE_ATTR } from 'client/components/common/BuildingBlocks/Fields/constants';
import TextBody from './TextBody';
import css from './Text.scss';
import ContentEditable from './ContentEditable';

type State = {
	html: string;
	editing: boolean;
};

type Props = Omit<BBCommonProps, 'children'> & { children?: string };

const AITextActions = React.lazy(() => import('client/components/common/BuildingBlocks/Text/AITextActions'));

const parseChildren = (html: string = '') => {
	if (cms.isCollectionReference(html)) {
		return `${cms.replaceCollectionReference(html) ?? html}`;
	}
	return html;
};

class Text extends React.Component<Props, State> {
	editableRef = React.createRef<HTMLDivElement>();

	constructor(props: Props) {
		super(props);

		this.state = {
			html: parseChildren(props.children ?? ''),
			editing: false,
		};
	}

	static getDerivedStateFromProps(props: Props, state: State): Partial<State> | null {
		const children = parseChildren(props.children ?? '');
		const isUpdatedChildrenFromProp = document.activeElement === document.body && children !== state.html;

		if (isUpdatedChildrenFromProp) {
			return { html: children, editing: false };
		}

		return null;
	}

	onChangeDebounced = debounce((getHtml: () => string) => {
		const html = getHtml();
		if (this.state.html !== html) this.save(html, true);
	}, 500);

	get maxRows() {
		return {
			maxRows: this.props.uiConfig.componentProps.styles?.lineClamp,
			maxRowsColor: this.props.uiConfig.componentProps.styles?.lineClampColor,
		};
	}

	onEditComplete = () => {
		this.onChangeDebounced.flush();
		this.disableEditing();
	};

	save = (html: string, isContinueEditing: boolean = false) => {
		const path = this.props.editableModeProps?.nodeProps?.['data-path'];
		const { currentMediaQuery } = this.props;

		if (!path || !this.state.editing) {
			console.warn("path isn't defined...");
			return;
		}

		// NOTE: children is always writes to defaultState, but for certain media query
		const childrenPath = `elements.${path}.${CHILDREN_KEY}.${COMPONENT_STATES.DEFAULT}`;

		const payload =
			this.props.editorMode === EditorMode.CONTENT
				? { value: { [this.props.mediaQuery.defaultPlatform]: html }, path: childrenPath }
				: { value: html, path: `${childrenPath}.${currentMediaQuery}` };

		transmitToAdminBbUpdate({
			id: Text.name,
			isStory: isLayerType(this.props).global,
			...payload,
		});

		this.setState({ editing: isContinueEditing, html });
	};

	updateSelectionRect = (p: { getHtml: () => string }) => {
		// trigger SelectionHint update to set current editable node sizes
		triggerFocus(this.editableRef.current);

		this.onChangeDebounced(p.getHtml);
	};

	enableEditing = () => {
		this.setState({ editing: true }, () => {
			// update SelectionHint and place cursor into contenteditable block
			defer(() => triggerFocus(this.editableRef.current?.querySelector('[contenteditable="true"]')));
		});
	};

	disableEditing = () => this.setState({ editing: false });

	renderToolbar = () => {
		const isEditing = this.state.editing;
		return (
			<SelectionToolbarPortal nodeID={this.props.uiConfig.nodeProps.id}>
				<ConditionalFeatureRender feature="create-ai-story">
					<React.Suspense>
						<AITextActions prompt={this.state.html} />
					</React.Suspense>
				</ConditionalFeatureRender>
				{this.props.editorMode === EditorMode.CONTENT ? null : (
					<button
						type="button"
						onClick={isEditing ? this.disableEditing : this.enableEditing}
						title={isEditing ? 'Save' : 'Edit'}
						data-color={isEditing ? 'brand' : ''}
					>
						{isEditing ? <CheckIcon width={13} /> : <EditIcon width={14} />}
					</button>
				)}
			</SelectionToolbarPortal>
		);
	};

	renderEditable() {
		const { editing } = this.state;
		const { editableModeProps, uiConfig } = this.props;
		const nodeProps: BBUiConfig['nodeProps'] = {
			...uiConfig.nodeProps,
			...editableModeProps?.nodeProps,
			style: {
				...uiConfig.nodeProps?.style,
				...editableModeProps?.nodeProps?.style,
			},
		};
		const className = cn(css.text, css.editable, uiConfig.nodeProps.className, {
			'cms-mark': cms.isCollectionReference(this.props.children),
		});

		if (editing) {
			const props = {
				...nodeProps,
				...this.props.stateAttrs,
				...this.props.eventListeners,
				className,
				[FIELD_EDITABLE_ATTR]: '', // prevent from dragging by <Points />
				onKeyUp: e => e.stopPropagation(), // prevent application keyboard shortcuts while editing text
				onKeyDown: e => e.stopPropagation(), // prevent application keyboard shortcuts while editing text
			};
			return (
				<div {...props} ref={this.editableRef}>
					<ContentEditable
						html={this.state.html}
						onChange={this.updateSelectionRect}
						onComplete={this.onEditComplete}
						editableRef={this.editableRef}
					/>
					{this.renderToolbar()}
				</div>
			);
		}

		const { maxRows, maxRowsColor } = this.maxRows;

		return (
			<>
				<TextBody.Raw
					eventListeners={{
						...this.props.eventListeners!,
						onDoubleClick: this.enableEditing,
						...(this.props.editorMode === EditorMode.CONTENT ? { onClick: this.enableEditing } : null),
					}}
					className={className}
					nodeProps={nodeProps}
					tag="div"
					containerRef={this.editableRef as Props['containerRef']}
					stateAttrs={this.props.stateAttrs}
					html={this.state.html}
					isEditableMode={this.props.isEditableMode}
					maxRows={maxRows}
					maxRowsBtnColor={maxRowsColor}
				/>

				{this.renderToolbar()}
			</>
		);
	}

	renderDefault() {
		const { nodeProps, componentProps } = this.props.uiConfig;
		const { maxRows, maxRowsColor } = this.maxRows;
		const Component = this.state.html.match(/(data-mention=)|(\$[^0-9][a-z]*)/gi)
			? TextBody.WithVariables
			: TextBody.Raw;
		const tag = componentProps[UNIFORM_PROPS.tag];

		return (
			<Component
				nodeProps={nodeProps}
				stateAttrs={this.props.stateAttrs}
				eventListeners={this.props.eventListeners}
				tag={isString(tag) ? tag : 'p'}
				html={this.state.html}
				containerRef={this.props.containerRef}
				className={cn(css.text, nodeProps.className)}
				isEditableMode={this.props.isEditableMode}
				maxRows={maxRows}
				maxRowsBtnColor={maxRowsColor}
			/>
		);
	}

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

export default withCardTransitionContext(Text);
