/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cn from 'classnames';

import type { BBCommonProps } from 'types/story';
import { replaceUrlHostname } from 'utils/assets';
import ChangeIcon from 'common/components/Icon/Change';
import VideoPlayer from 'common/components/VideoPlayer';
import { IFRAME_ACTIONS, transmitTo } from 'common/utils/iframe-tunnel';
import { useClientSelector } from 'client/reducers';
import { selectStorycardsDomain } from 'client/reducers/story/selectors';
import { SelectionToolbarPortal } from 'client/components/common/SelectionHint/Toolbar';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';

import css from './Video.scss';

type Props = BBCommonProps;

const Video = (props: Props) => {
	const storycardsDomain = useClientSelector(selectStorycardsDomain);

	const { editableModeProps: EMP, stateAttrs, isEditableMode, uiConfig } = props;
	const { videoSrc, videoPoster, muted, loop, controls, autoplay } = uiConfig.componentProps.other;
	const isHidden = uiConfig.componentProps.styles.display === 'none';
	// const src = videoSrc === 'none' || !videoSrc ? '' : prependUrlProtocol(videoSrc, 'https');
	const src = replaceUrlHostname(videoSrc, storycardsDomain);
	const poster = replaceUrlHostname(videoPoster, storycardsDomain);
	const isLoop = isEditableMode ? false : loop ?? false;
	const isMuted = isEditableMode ? true : muted ?? false;
	const isAutoplay = isEditableMode ? false : autoplay ?? false;
	const isControls = isEditableMode ? false : controls ?? false;

	return (
		<div
			{...uiConfig.nodeProps}
			{...stateAttrs}
			{...props.eventListeners}
			{...EMP?.nodeProps}
			style={uiConfig.nodeProps?.style}
			className={cn(css.video, uiConfig.nodeProps.className, EMP?.nodeProps?.className)}
			ref={props.containerRef as React.RefObject<HTMLDivElement>}
		>
			{!isHidden && src ? (
				<VideoPlayer
					key={`${props._id}-${src}-${poster}`} // key is important to update preview on src/poster change
					src={src}
					poster={poster}
					controls={isControls}
					muted={isMuted}
					loop={isLoop}
					autoplay={isAutoplay}
					title={uiConfig.componentProps.title}
					isEditableMode={isEditableMode}
				/>
			) : (
				<div className={css.empty} data-text="No video" />
			)}

			{props.isEditableMode && !isHidden && (
				<SelectionToolbarPortal nodeID={uiConfig.nodeProps.id}>
					<button
						title="Repalce video source"
						type="button"
						onClick={() => {
							// Flow: send msg to admin -> read msg -> emit inspector event -> call event listener ->
							// -> call file upload
							transmitTo({
								id: 'Video',
								target: 'admin',
								action: IFRAME_ACTIONS.UPLOAD_MEDIA,
							});
						}}
					>
						<ChangeIcon width={11} />
					</button>
				</SelectionToolbarPortal>
			)}
		</div>
	);
};

export default withCardTransitionContext(Video);
