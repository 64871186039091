import React, { useEffect, useState, useCallback, useMemo, useRef, Suspense } from 'react';
import cn from 'classnames';
import { kebabCase } from 'lodash';

import type { IOrganizationIntegrations } from 'types/index';
import type { BBCommonProps, BBStates, BBStylesProp } from 'types/story';
import { analytics } from 'utils/analytics';
import { convertVwToPx } from 'utils/helpers';
import { isLayerType } from 'utils/blocks/is-layer-type';
import { IFRAME_ACTIONS, transmitTo } from 'utils/iframe-tunnel';
import {
	parseImgSrc,
	isGif,
	isSvg,
	isImageEditableURL,
	IMAGE_PARAMS,
	replaceUrlHostname,
	isLocalAsset,
	buildLocalAssetsUrl,
} from 'utils/assets';
import cms from 'common/utils/cms';
import { location } from 'utils/url-helper';
import { buildImageUrl } from 'utils/build-image-src';
import CheckIcon from 'common/components/Icon/CheckMark';
import ChangeIcon from 'common/components/Icon/Change';
import EditIcon from 'common/components/Icon/Edit';
import { selectCardId } from 'client/reducers/card/selectors';
import { selectIntegrations, selectStoryFacade, selectStorycardsDomain } from 'client/reducers/story/selectors';
import { COMPONENT_STATES, IMG_TINT_ATTR } from 'common/constants';
import { CSS_PROPS, UNIFORM_PROPS } from 'common/constants/component-props';
import { selectIsNodeSelected } from 'client/reducers/editor/selectors';
import { useClientSelector } from 'client/reducers';
import { transmitToAdminBbUpdate } from 'client/utils/transmit-to-admin-bb-update';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { getLinkData, getLinkProps, scrollIntoViewById } from 'client/components/common/BuildingBlocks/utils/common';
import { EventProviderContextT, withEventProvider } from 'client/components/pages/Story/EventProvider/Context';
import { SelectionToolbarPortal } from 'client/components/common/SelectionHint/Toolbar';

import AIImageActions from './AIImageActions';
import ImageLoadable from './ImageLoadable';
import css from './Img.scss';

const { isPreview } = location.client;

const ImageEditor = React.lazy(() => import('client/components/common/BuildingBlocks/Img/ImageEditor'));

const onReplaceImageClick = () => {
	// Flow: send msg to admin -> read msg -> emit inspector event -> call event
	// listener -> -> call image upload
	transmitTo({
		id: 'Image',
		target: 'admin',
		action: IFRAME_ACTIONS.UPLOAD_MEDIA,
	});
};

const getBBox = (el: HTMLElement, styles: BBStylesProp) => {
	const { top, left } = el.getBoundingClientRect();
	const borders = getBorders(styles);

	return {
		top: top + borders.top,
		left: left + borders.left,
		width: el.offsetWidth - (borders.left + borders.right),
		height: el.offsetHeight - (borders.top + borders.bottom),
	};
	// get offsetWidth/Height instead of getBoundingClientRect for not take in account scale transformations
};

const parsePropertyWithCmsReplacer = (value = '') => {
	if (cms.isCollectionReference(value)) {
		return `${cms.replaceCollectionReference(value) ?? value}`;
	}
	return value;
};

// raw Img component without building block enhancer
type RawImgProps = Omit<BBCommonProps, 'children'> & { isRaw: boolean };

// common building block usage
type DefaultImgProps = BBCommonProps & EventProviderContextT;

type ImgProps = RawImgProps | DefaultImgProps;

const Img = (props: ImgProps) => {
	const storycardsDomain = useClientSelector(selectStorycardsDomain);

	// props
	const { editableModeProps: EMP, stateAttrs, currentMediaQuery } = props;
	const { nodeProps } = props.uiConfig;
	const isStoryElement = isLayerType(props).global;
	const integrations = useClientSelector(selectIntegrations);
	const { Component, ...componentProps } = getComponent(props, integrations?.urlParams?.params);
	const nodeID = props.uiConfig.nodeProps.id;

	// image
	const { styles } = props.uiConfig.componentProps;
	const isHidden = styles.display === 'none';
	const bgImgProp = parsePropertyWithCmsReplacer(styles.backgroundImage ?? '');
	const bgSizeProp = styles.backgroundSize ?? '';
	const bgPositionProp = styles.backgroundPosition ?? '';
	const bgImgParsed = useMemo(
		() =>
			// transform all background properties into single src string value to work with src only
			parseImgSrc(bgImgProp, {
				backgroundSize: bgSizeProp,
				backgroundPosition: bgPositionProp,
				storycardsDomain,
			}),
		[bgImgProp, bgSizeProp, bgPositionProp, storycardsDomain]
	);

	const src = React.useMemo(() => {
		let value = bgImgParsed.href;
		if (!isPreview && isLocalAsset(value)) value = buildLocalAssetsUrl(value);
		value = replaceUrlHostname(value, storycardsDomain);
		return value;
	}, [bgImgParsed.href, storycardsDomain]);

	// state
	const { storyId } = useClientSelector(selectStoryFacade);
	const cardId = useClientSelector(selectCardId)!;
	const isSelected = useClientSelector(state => selectIsNodeSelected(state, nodeID));
	const [isEdit, setIsEdit] = useState(false);
	const [, setIsMounted] = useState(false);

	const srcEditable = useRef('');
	const setSrcEditable = useCallback((val: string) => {
		srcEditable.current = val;
	}, []);

	const save = useCallback(
		(value: string) => {
			const np = EMP?.nodeProps ?? {};
			const path = np['data-path'];
			let state: BBStates = COMPONENT_STATES.DEFAULT;
			Object.keys(COMPONENT_STATES).forEach(key => {
				if (np[`data-${kebabCase(COMPONENT_STATES[key])}`] !== undefined) {
					state = COMPONENT_STATES[key];
				}
			});

			const urlValue = new URL(value);
			const basePath = `elements.${path}.uiConfig.componentProps.styles.${state}.${currentMediaQuery}`;
			const updatesMap = new Map<string, string>();

			// read edited param from src and store as separated css property ("background-size")
			const nextBgSize = urlValue.searchParams.get(IMAGE_PARAMS.fsz)!;
			if (nextBgSize !== bgImgParsed.fsz) {
				updatesMap.set(`${basePath}.${CSS_PROPS.background.backgroundSize}`, nextBgSize ?? undefined);
			}

			// read edited param from src and store as separated css property ("background-position")
			const nextBgPos = urlValue.searchParams.get(IMAGE_PARAMS.rect)!;
			if (nextBgPos !== bgImgParsed.rect) {
				updatesMap.set(`${basePath}.${CSS_PROPS.background.backgroundPosition}`, nextBgPos);
			}

			transmitToAdminBbUpdate({
				id: Img.name,
				path: [...updatesMap.keys()],
				value: [...updatesMap.values()],
				isStory: isStoryElement,
			});
		},
		[EMP?.nodeProps, isStoryElement, currentMediaQuery, bgImgParsed]
	);

	// Close edit mode on bg image change/update
	useEffect(() => {
		setIsEdit(false);
	}, [bgImgProp, bgSizeProp, bgPositionProp, currentMediaQuery]);

	// re-render once to get a bbox
	useEffect(() => {
		setIsMounted(true);
	}, []);

	// Save on selection change
	const isChanged = srcEditable.current && srcEditable.current !== src;
	useEffect(() => {
		if (isEdit && !isSelected) {
			setIsEdit(false);

			if (isChanged) save(srcEditable.current);
		}
	}, [isSelected, isEdit, save, isChanged]);

	const el = props.containerRef?.current;

	const handleSave = () => {
		setIsEdit(v => {
			if (v && srcEditable.current && srcEditable.current !== src) {
				save(srcEditable.current);
			}
			return !v;
		});
	};

	// Event listeners
	const { externalLink, internalLink, scrollTo } = getLinkData({ uiConfig: props.uiConfig, cardId });
	const eventListeners = props.isEditableMode
		? {}
		: {
				onClick: (event: React.MouseEvent) => {
					if (internalLink || externalLink) {
						analytics.onLinkClick({
							storyId,
							cardId,
							name: props.uiConfig.editorProps.name,
						});

						if (location.client.isPreview && externalLink && !props.uiConfig.componentProps.btnTarget) {
							event.preventDefault();
						}
					}

					props.eventListeners?.onClick?.(event);

					if (scrollTo.currentPageBlockId) {
						scrollIntoViewById(scrollTo.currentPageBlockId, { hash: true });
						return;
					}

					if (internalLink && 'eventProvider' in props) {
						props.eventProvider.events.flow(internalLink, {
							isDirectId: true,
							scrollTo: scrollTo.anotherPageBlockId,
						});
					}
				},
			};

	const isNonEditableFormat = isGif(src) || isSvg(src) || !isImageEditableURL(src, storycardsDomain);
	const className = cn(css.img, nodeProps.className, EMP?.nodeProps?.className, {
		[css.cover]: bgImgParsed.fsz === 'cover',
		[css.contain]: bgImgParsed.fsz === 'contain',
		[css.defaultCover]: !bgImgParsed.rect || isNonEditableFormat,
		[css.link]: externalLink || internalLink,
		'cms-mark': cms.isCollectionReference(styles.backgroundImage),
	});

	// is reused in other building block to achieve image functionality
	const isCustom = props.custom !== undefined;

	return (
		<Component
			{...(isCustom ? null : componentProps)}
			{...(isCustom ? null : nodeProps)}
			{...(isCustom ? null : stateAttrs)}
			{...(isCustom ? null : props.eventListeners)}
			{...(isCustom ? null : eventListeners)}
			{...(isCustom ? null : EMP?.nodeProps)}
			onDoubleClick={e => {
				if (!props.isEditableMode || e.target.nodeName === 'INPUT' || isNonEditableFormat) return;
				handleSave();
			}}
			style={isCustom ? props.custom?.style : nodeProps?.style}
			className={className}
			ref={props.containerRef}
		>
			{/* Placeholder */}
			{!src && <div className={css.empty} data-text="No image" />}

			{/* Image */}
			{src && !isEdit && !isHidden && el && (
				<ImageLoadable
					alt={props.uiConfig.componentProps[UNIFORM_PROPS.alt]}
					storycardsDomain={storycardsDomain}
					{...buildImageUrl({
						src,
						width: getBBox(el, styles).width,
						storycardsDomain,
						type: 'image',
						platform: currentMediaQuery,
					})}
				/>
			)}

			{/* Tint */}
			{!isEdit && <span {...{ [IMG_TINT_ATTR]: '' }} />}

			{/* Editor */}
			{src && isEdit && el && (
				<Suspense fallback={null}>
					<ImageEditor
						src={src}
						storycardsDomain={storycardsDomain}
						setEditableSrc={setSrcEditable}
						bbox={getBBox(el, styles)}
						nodeID={nodeID}
					/>
				</Suspense>
			)}

			{/* Toolbar */}
			{props.isEditableMode && !isHidden && (
				<SelectionToolbarPortal nodeID={nodeID}>
					{!isEdit && (
						<>
							<button title="Replace image source" type="button" onClick={onReplaceImageClick}>
								<ChangeIcon width={11} />
							</button>
							<AIImageActions />
						</>
					)}
					{!isNonEditableFormat && (
						<button
							type="button"
							onClick={isEdit ? handleSave : () => setIsEdit(true)}
							title={isEdit ? 'Apply changes' : 'Edit image'}
							data-color={isEdit ? 'brand' : ''}
						>
							{isEdit ? <CheckIcon width={13} /> : <EditIcon width={14} />}
						</button>
					)}
				</SelectionToolbarPortal>
			)}
		</Component>
	);
};

function getComponent(
	props: ImgProps,
	integrationUrlParams: NonNullable<IOrganizationIntegrations['urlParams']>['params']
): { Component: any; href?: any; target?: string; rel?: string | undefined } {
	const externalLink = parsePropertyWithCmsReplacer(props.uiConfig.componentProps[UNIFORM_PROPS.btnLink]);

	if (externalLink && !props.isEditableMode) {
		return getLinkProps(props.uiConfig, { link: externalLink, integrationUrlParams });
	}

	return {
		Component: 'div',
	};
}

function getBorders(styles: React.CSSProperties) {
	const borderStylesMask = unwrapCSSShorthand(styles.borderStyle?.split(' ').map(s => +(s !== 'none')) || [1]);
	const borderWidths = unwrapCSSShorthand(
		typeof styles.borderWidth === 'number'
			? [styles.borderWidth]
			: styles.borderWidth?.split(' ').map(w => +convertVwToPx(w)) || [0]
	);

	return {
		top: borderWidths[0] * borderStylesMask[0],
		right: borderWidths[1] * borderStylesMask[1],
		bottom: borderWidths[2] * borderStylesMask[2],
		left: borderWidths[3] * borderStylesMask[3],
	};
}

function unwrapCSSShorthand<T>(shorthand: T[]): [T, T, T, T] {
	if (shorthand.length === 0) throw new Error('CSS shorthand is empty');
	if (shorthand.length === 1) {
		return [shorthand[0], shorthand[0], shorthand[0], shorthand[0]];
	}
	if (shorthand.length === 2) {
		return [shorthand[0], shorthand[1], shorthand[0], shorthand[1]];
	}
	if (shorthand.length === 3) {
		return [shorthand[0], shorthand[1], shorthand[2], shorthand[1]];
	}
	return shorthand.slice(0, 4) as [T, T, T, T];
}

export const RawImageComponent = Img;

export default withEventProvider(withCardTransitionContext(Img));
