import { find, maxBy, pickBy, inRange } from 'lodash';
import { SCORE } from 'common/constants';
import { CardFacadeBase } from 'utils/facades/card-facade-base';
import type { CardFlowEvent, StorySettingsType } from 'types/story';
import type { TotalScoreType, TotalCharacterPointsType } from 'client/utils';

export enum CountType {
	score = 'score', // Total score calculated relative to the maximum
	character = 'character', // Total number of points scored by an individual character
}

type GetNextEventByScore = (score: TotalScoreType) => CardFlowEvent | null | undefined;

type GetNextEventByResultPoints = (score: TotalCharacterPointsType) => CardFlowEvent | null | undefined;

class NavigationFacade extends CardFacadeBase {
	private getNextEventByScore: GetNextEventByScore = ({ scoreRelative: totalScore }) => {
		if (!this.data) {
			return null;
		}

		return find(this.data.events, ({ score }) => {
			if (!score) {
				return false;
			}
			const min = score[0] || SCORE.RANGE.MIN;
			const max = Number.isInteger(score[1]) ? score[1] + 1 : Infinity;
			return inRange(totalScore, min, max);
		});
	};

	private getNextEventByCharacterPoints: GetNextEventByResultPoints = totalCharacterPoints => {
		const { characters } = this;
		const totalCardCharacterPoints = pickBy(totalCharacterPoints, (value, key) => key in characters);
		const [characterId] = maxBy(Object.entries(totalCardCharacterPoints), ([, value]) => value) ?? [];
		return characterId === undefined ? null : find(this.data.events, o => o._id === characterId);
	};

	get nextEvent() {
		return {
			[CountType.score]: this.getNextEventByScore,
			[CountType.character]: this.getNextEventByCharacterPoints,
		};
	}

	get characters() {
		return this.data.events.reduce(
			(acc, event) => {
				acc[event._id] = { name: event.name, cardId: this.data._id };
				return acc;
			},
			{} as Exclude<StorySettingsType['characters'], undefined>
		);
	}

	getCountType = (storySettings: StorySettingsType) => {
		return storySettings.cards?.[this.data._id]?.countType ?? CountType.score;
	};
}

export { NavigationFacade };
