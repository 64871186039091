import { clamp, set } from 'lodash';
import { NUM_SELECTED_ANSWERS, CARD_TYPE } from 'common/constants';
import { transformElementDefaults } from 'utils/facades/utils';
import CARD_FACADES, { FacadeValues } from 'utils/facades/card-facades';
import type { CardData, StorySettingsOfCard, StorySettingsType } from 'types/story';

function hasFeature(type: CardData['type']) {
	return {
		results: type === CARD_TYPE.POLL || type === CARD_TYPE.THIS_OR_THAT,
		correct: type === CARD_TYPE.TRIVIA || type === CARD_TYPE.TRUE_OR_FALSE || type === CARD_TYPE.SORTABLE_TRIVIA,
		score: type === CARD_TYPE.TRIVIA || type === CARD_TYPE.TRUE_OR_FALSE,
		answer:
			type === CARD_TYPE.TRIVIA ||
			type === CARD_TYPE.SORTABLE_TRIVIA ||
			type === CARD_TYPE.SORTABLE_POLL ||
			type === CARD_TYPE.POLL ||
			type === CARD_TYPE.TRUE_OR_FALSE ||
			type === CARD_TYPE.THIS_OR_THAT ||
			type === CARD_TYPE.PERSONALITY_TEST,
		answerScore: type === CARD_TYPE.PERSONALITY_TEST || type === CARD_TYPE.SORTABLE_TRIVIA,
		multipleAnswer: type === CARD_TYPE.POLL || type === CARD_TYPE.SORTABLE_TRIVIA,
		submitButton: type === CARD_TYPE.POLL || type === CARD_TYPE.SORTABLE_POLL || type === CARD_TYPE.FORM,
		autoSubmitOnComplete: type === CARD_TYPE.SORTABLE_TRIVIA,
	};
}

type ShowResultsParams = {
	storySettings: StorySettingsType;
	cardType: CardData['type'];
	cardId: string;
};

function showResults({ storySettings, cardType, cardId }: ShowResultsParams) {
	if (storySettings.cards?.[cardId]?.showResults !== undefined) {
		return storySettings.cards[cardId].showResults ?? false;
	}

	// fallback to old json
	return CardFacade.hasFeature(cardType).results;
}

enum AnswersMinMaxProp {
	min = 'min',
	max = 'max',
}

type GetAnswersMinMax = {
	cardType: CardData['type'];
	storyCardSettings: StorySettingsOfCard | undefined;
	prop: AnswersMinMaxProp;
};

function getAnswersMinMax({ cardType, storyCardSettings, prop }: GetAnswersMinMax) {
	if (!CardFacade.hasFeature(cardType).answer) {
		return 0;
	}
	const { MIN, MAX } = NUM_SELECTED_ANSWERS;
	const answersMinMax = storyCardSettings?.answersMinMax;
	const [min, max] = Array.isArray(answersMinMax) ? answersMinMax : [MIN, MIN];
	const current = prop === AnswersMinMaxProp.min ? min : max;
	switch (cardType) {
		case CARD_TYPE.POLL:
			return clamp(current, MIN, MAX);
		case CARD_TYPE.SORTABLE_TRIVIA:
			return prop === AnswersMinMaxProp.min ? MIN : Object.keys(storyCardSettings?.answers ?? []).length;
		default:
			return MIN;
	}
}

class CardFacade<T extends FacadeValues = FacadeValues> {
	static getCardNameByType(type: CardData['type']): string {
		const nameAlias = {
			[CARD_TYPE.SORTABLE_TRIVIA]: 'SORTABLE TRIVIA',
			[CARD_TYPE.SORTABLE_POLL]: 'SORTABLE POLL',
			[CARD_TYPE.PERSONALITY_TEST]: 'PERSONALITY TEST',
			[CARD_TYPE.TRUE_OR_FALSE]: 'TRUE OR FALSE',
			[CARD_TYPE.THIS_OR_THAT]: 'THIS OR THAT',
			[CARD_TYPE.FORM]: 'LEADS FORM',
		};

		return nameAlias[type] || type;
	}

	static hasFeature(type: CardData['type']) {
		return hasFeature(type);
	}

	static getShowResults(params: ShowResultsParams) {
		return showResults(params);
	}

	static getAnswersMax = (cardType: CardData['type'], storyCardSettings: StorySettingsOfCard | undefined) =>
		getAnswersMinMax({ cardType, storyCardSettings, prop: AnswersMinMaxProp.max });

	static getAnswersMin = (cardType: CardData['type'], storyCardSettings: StorySettingsOfCard | undefined) =>
		getAnswersMinMax({ cardType, storyCardSettings, prop: AnswersMinMaxProp.min });

	card: T;

	/**
	 * @param card {Object} Card object
	 */
	constructor(card: CardData) {
		this.card = new CARD_FACADES[card.type](card as CardData) as T;
	}

	get type() {
		return this.card.data.type;
	}

	get events() {
		return this.card.data.events;
	}

	get elements() {
		return this.card.data.elements;
	}

	setElements(elements) {
		return set(this, 'card.data.elements', elements);
	}

	get data() {
		return this.card.data;
	}

	get cardId() {
		return this.card.data._id;
	}

	get hasFeature() {
		return hasFeature(this.type);
	}

	getShowCorrect(storySettings: StorySettingsType) {
		if (storySettings.cards?.[this.cardId]?.showCorrect !== undefined) {
			return storySettings.cards[this.cardId].showCorrect;
		}

		// fallback to old json
		return this.hasFeature.correct;
	}

	getShowResults(storySettings: StorySettingsType) {
		return showResults({ storySettings, cardType: this.type, cardId: this.cardId });
	}

	getForceSubmitButton(storySettings: StorySettingsType) {
		return (
			this.type === CARD_TYPE.POLL &&
			(Array.isArray(storySettings.cards?.[this.cardId]?.answersMinMax) ||
				!!storySettings.cards?.[this.cardId]?.forceSubmitBtn)
		);
	}

	/**
	 * Transform configurable defaults of every element in card
	 * relatively to mediaQuery config of Story
	 *
	 * @param mediaQuery {Object} StoryFacade.getMediaQuery
	 */
	transformElementsDefaults({ mediaQuery }) {
		const { defaultPlatform } = mediaQuery;
		let { elements } = this;

		elements = transformElementDefaults(defaultPlatform, elements) as CardData['elements'];

		this.setElements(elements);
	}
}

export { CardFacade };
