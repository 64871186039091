import { COMPONENT_TYPE as TYPE, LAYER_TYPE, COMPONENT_DISPLAY_NAME } from 'common/constants';
import { generateElementId, generateNodeId } from 'utils/generate-id';
import View from 'client/components/common/BuildingBlocks/Video/Video';

export const VIDEO = () => {
	const _id = generateElementId();

	return {
		view: View,
		get model() {
			const type = TYPE.VIDEO;

			return {
				_id,
				type,
				uiConfig: {
					layer: LAYER_TYPE.CONTENT,
					editorProps: {
						name: COMPONENT_DISPLAY_NAME[type],
						selectable: true,
						draggable: true,
						expandable: false,
						expanded: false,
					},
					nodeProps: {
						id: generateNodeId(_id, type),
					},
					componentProps: {
						styles: {},
						other: {},
					},
				},
				children: [],
			};
		},
	};
};
