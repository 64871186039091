/* eslint-disable camelcase */
import { pickBy } from 'lodash';
import { appLog } from 'utils/helpers';
import { location as UrlHelperLocation } from 'utils/url-helper';

const { isPreview } = UrlHelperLocation.client;
const log = appLog.extend('utils:analytics');

const pickValid = <T extends Record<string, any>>(o: T): Partial<T> =>
	pickBy(o, v => v !== null && v !== undefined && v !== '') as Partial<T>;

/**
 * @info https://developers.google.com/analytics/devguides/collection/gtagjs/events
 */
export function track({
	type,
	action,
	payload,
}: {
	type: string;
	action: string;
	payload: { [key: string]: string | number | boolean };
}) {
	try {
		if (isPreview) {
			return;
		}

		if (!window.gtag) {
			console.warn("gtag is't initialized");
			return;
		}

		const params = pickValid(payload);
		log('gtag', type, action, params);
		window.gtag(type, action, params);
	} catch (error) {
		console.warn('Failed to track analytics event', error);
	}
}

export const analytics = {
	onLinkClick: (params: { storyId: string; cardId: string; name: string }) =>
		track({
			type: 'event',
			action: `link click story ${params.storyId} | card ${params.cardId} | ${params.name}`,
			payload: {
				event_category: 'Link click',
				layerName: params.name,
				storyId: params.storyId,
				cardId: params.cardId,
			},
		}),
	onApiRetry: (attemptNumber, apiRequestName) =>
		track({
			type: 'event',
			action: `Retry ${attemptNumber} - ${apiRequestName}`,
			payload: {
				event_category: 'Retry to api',
				event_label: `${apiRequestName}`,
			},
		}),
	onApiError: (errorCode, apiRequestName) =>
		track({
			type: 'event',
			action: `${apiRequestName}`,
			payload: {
				event_category: 'Error from api',
				event_label: `${errorCode} code`,
			},
		}),
	onOffline: (storyId, cardId, requestRoute) =>
		track({
			type: 'event',
			action: `offline mode storyid ${storyId} card ${cardId}`,
			payload: {
				event_category: 'offline mode no session',
				event_label: `${requestRoute}`,
			},
		}),
	onSendAnswer: (storyId: string, cardId: string, answerId: string, answerText: string) =>
		track({
			type: 'event',
			action: `Send Answer story ${storyId} card ${cardId} `,
			payload: {
				event_category: 'Send answer',
				event_label: `answer ${answerId} ${answerText}`,
			},
		}),
};

let isFirstPageView = true;

/**
 * @param props.location The page's URL.
 * @param props.path The path portion of location. This value must start with a slash (/) character.
 * @param props.title The page's title.
 * @param props.send_to GA_MEASUREMENT_ID
 * @info https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 */
export const pageview = (props: { location: string; path: string; title: string; gaUniversalId?: string }) => {
	if (isPreview || !window.gtag) return;

	const { gaUniversalId, ...validParams } = pickValid({
		page_path: props.path,
		page_title: props.title,
		page_location: props.location,
		gaUniversalId: props.gaUniversalId,
	});

	// Setting properties globally to ensure GA uses these values consistently.
	// This prevents GA from using properties from a previous event if not provided.
	if (validParams.page_path) window.gtag('set', 'page_path', validParams.page_path);
	if (validParams.page_title) window.gtag('set', 'page_title', validParams.page_title);
	if (validParams.page_location) window.gtag('set', 'page_location', validParams.page_location);

	log('pageview', validParams);

	if (isFirstPageView) {
		// Initial 'page_view' event doesn't send automatically by default (see `{ send_page_view: false }`)
		window.gtag('event', 'page_view', validParams);
		isFirstPageView = false;
	} else if (gaUniversalId) {
		// GA4 sends 'page_view' events automatically by default, while GA3 (Universal) requires manual sending.
		window.gtag('event', 'page_view', { ...validParams, send_to: gaUniversalId });
	}
};
