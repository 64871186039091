/* eslint-disable jsx-a11y/media-has-caption */
import _ from 'lodash';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';

import { cutURLMethod } from 'common/utils/assets';
import { OTHER_PROPS } from 'common/constants/component-props';
import type { ClientReducerState } from 'client/reducers';
import type { BBCommonProps } from 'types/story';
import withCardTransitionContext from 'client/components/common/BuildingBlocks/BuildingBlockEnhancer';
import { getExposeDuration, getExposeParams } from 'client/components/common/BuildingBlocks/utils/common';
import { ScriptRenderer } from 'client/components/common/ScriptRenderer/ScriptRenderer';

import css from './Card.scss';

type CardProps = BBCommonProps & ConnectedProps<typeof connector> & { onTransitionEnd: (p: BBCommonProps) => void };

class Card extends React.Component<CardProps> {
	transitionTimeout?: number;

	componentDidUpdate(prevProps: any) {
		const { effect, duration } = this.exposeParams;

		if (effect && this.props.in !== prevProps.in) {
			clearTimeout(this.transitionTimeout);
			this.transitionTimeout = window.setTimeout(
				() => this.props.onTransitionEnd(this.props),
				effect ? duration : 0
			);
		}
	}

	get exposeParams() {
		return getExposeParams({
			exposeEffect: this.props.exposeEffect,
			exposeDuration: this.props.exposeDuration,
			bbType: this.props.type,
		});
	}

	renderEditable() {
		const props = { ...this.props.editableModeProps?.nodeProps };
		return this.renderDefault(props);
	}

	renderDefault = (customProps: any = {}) => {
		// @ts-expect-error ts-migrate FIXME
		const { uiConfig, stateAttrs, eventListeners, transitionCSS } = this.props;
		const { duration } = this.exposeParams;
		const backgroundVideo = cutURLMethod(uiConfig.componentProps.other?.[OTHER_PROPS.card.backgroundVideo] ?? '');
		const backgroundPoster = cutURLMethod(uiConfig.componentProps.other?.[OTHER_PROPS.card.backgroundPoster] ?? '');

		return (
			<div
				{...uiConfig.nodeProps}
				{...stateAttrs}
				{...eventListeners}
				{...customProps}
				style={{
					...uiConfig.nodeProps.style,
					...(transitionCSS ? { transitionDuration: `${duration}ms` } : null),
				}}
				className={classNames(css.card, transitionCSS, uiConfig.nodeProps.className, customProps.className)}
			>
				<ScriptRenderer
					value={this.props.uiConfig.componentProps?.other?.embedCode ?? ''}
					disabled={this.props.isEditableMode}
					scriptOnly
				/>
				<div
					className={css.tint}
					style={{ background: uiConfig.componentProps.other[OTHER_PROPS.card.tint] }}
				/>
				{backgroundVideo && backgroundVideo !== 'none' && (
					<video
						className={css.videoBg}
						src={backgroundVideo}
						poster={backgroundPoster}
						playsInline
						muted={uiConfig.componentProps.other[OTHER_PROPS.card.muted] ?? true}
						loop={uiConfig.componentProps.other[OTHER_PROPS.card.loop] ?? false}
						autoPlay={!this.props.isEditableMode}
						preload={backgroundPoster ? 'none' : 'metadata'}
					/>
				)}
			</div>
		);
	};

	render() {
		return this.props.isEditableMode ? this.renderEditable() : this.renderDefault();
	}
}

const connector = connect((state: ClientReducerState, ownProps: BBCommonProps) => ({
	exposeDuration: getExposeDuration(
		_.get(state, `story.storyVersions.latest.settings.cards.${state.card._id!}.expose.duration`, '')
	),
	exposeEffect: _.get(ownProps.uiConfig, 'expose.effect'),
}));

export default connector(withCardTransitionContext(Card));
