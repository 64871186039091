/**
 * @fileoverview The names of the supported component properties available for editing are documented in this file.
 * These properties correspond to the property names of the `BBUiConfig['componentProps']` object.
 */

/**
 * List of supported css properties, divided into conditional groups
 */
export const CSS_PROPS = {
	grid: {
		gridTemplateRows: 'gridTemplateRows',
		gridTemplateColumns: 'gridTemplateColumns',
		gap: 'gap',
		rowGap: 'rowGap',
		columnGap: 'columnGap',
		gridAutoRows: 'gridAutoRows',
		gridAutoColumns: 'gridAutoColumns',
		gridRow: 'gridRow',
		gridColumn: 'gridColumn',
	},
	layout: {
		display: 'display',
		marginLeft: 'marginLeft',
		marginRight: 'marginRight',
		marginTop: 'marginTop',
		marginBottom: 'marginBottom',
		paddingLeft: 'paddingLeft',
		paddingRight: 'paddingRight',
		paddingTop: 'paddingTop',
		paddingBottom: 'paddingBottom',
		flex: 'flex',
		flexDirection: 'flexDirection',
		flexWrap: 'flexWrap',
		flexGrow: 'flexGrow',
		flexShrink: 'flexShrink',
		flexBasis: 'flexBasis',
		alignItems: 'alignItems',
		justifyContent: 'justifyContent',
		alignSelf: 'alignSelf',
		justifySelf: 'justifySelf',
	},
	size: {
		width: 'width',
		height: 'height',
		minWidth: 'minWidth',
		minHeight: 'minHeight',
		maxWidth: 'maxWidth',
		maxHeight: 'maxHeight',
		overflow: 'overflow',
	},
	position: {
		position: 'position',
		left: 'left',
		right: 'right',
		top: 'top',
		bottom: 'bottom',
		zIndex: 'zIndex',
		float: 'float',
		clear: 'clear',
	},
	text: {
		fontFamily: 'fontFamily',
		fontWeight: 'fontWeight',
		fontSize: 'fontSize',
		color: 'color',
		textAlign: 'textAlign',
		lineHeight: 'lineHeight',
		direction: 'direction',
		lineClamp: 'lineClamp',
		// line clamp text color (see CSS_PROPS.text.lineClamp)
		lineClampColor: 'lineClampColor',
	},
	background: {
		backgroundColor: 'backgroundColor',
		backgroundImage: 'backgroundImage',
		backgroundPosition: 'backgroundPosition',
		backgroundRepeat: 'backgroundRepeat',
		backgroundSize: 'backgroundSize',
		objectFit: 'objectFit',
		objectPosition: 'objectPosition',
	},
	borders: {
		borderWidth: 'borderWidth',
		borderStyle: 'borderStyle',
		borderColor: 'borderColor',
		borderRadius: 'borderRadius',
		borderTopRightRadius: 'borderTopRightRadius',
		borderTopLeftRadius: 'borderTopLeftRadius',
		borderBottomLeftRadius: 'borderBottomLeftRadius',
		borderBottomRightRadius: 'borderBottomRightRadius',
	},
	effects: {
		opacity: 'opacity',
		boxShadow: 'boxShadow',
		textShadow: 'textShadow',
		// transform: 'transform',
		// transition: 'transition',
		filter: 'filter',
		cursor: 'cursor',
		pointerEvents: 'pointerEvents',
	},
	svg: {
		strokeWidth: 'strokeWidth',
	},
	color: {
		color: 'color',
		backgroundColor: 'backgroundColor',
	},
	shape: {
		lineThickness: '__shapeLineThickness',
	},
	field: {
		style: {
			padding: {
				top: 'fieldPT',
				right: 'fieldPR',
				bottom: 'fieldPB',
				left: 'fieldPL',
			},
			text: {
				phColor: 'placeholderColor',
			},
			box: {
				fill: 'boxFill',
				stroke: 'boxStroke',
				iconColor: 'boxIconColor',
			},
		},
		errorStyle: {
			text: {
				color: 'errorColor',
				fontFamily: 'errorFontFamily',
				fontWeight: 'errorFontWeight',
				fontSize: 'errorFontSize',
				lineHeight: 'errorLineHeight',
				textAlign: 'errorTextAlign',
				direction: 'errorDirection',
			},
			padding: {
				top: 'errorPaddingTop',
				right: 'errorPaddingRight',
				bottom: 'errorPaddingBottom',
				left: 'errorPaddingLeft',
			},
		},
	},
	image: {
		tint: 'imgTint',
	},
	counter: {
		unit: {
			padding: {
				top: 'counterPaddingTop',
				right: 'counterPaddingRight',
				bottom: 'counterPaddingBottom',
				left: 'counterPaddingLeft',
			},
			background: {
				backgroundColor: 'counterBackgroundColor',
				backgroundImage: 'counterBackgroundImage',
				backgroundPosition: 'counterBackgroundPosition',
				backgroundRepeat: 'counterBackgroundRepeat',
				backgroundSize: 'counterBackgroundSize',
				objectFit: 'counterObjectFit',
				objectPosition: 'counterObjectPosition',
			},
			borders: {
				borderWidth: 'counterBorderWidth',
				borderStyle: 'counterBorderStyle',
				borderColor: 'counterBorderColor',
				borderRadius: 'counterBorderRadius',
				borderTopRightRadius: 'counterBorderTopRightRadius',
				borderTopLeftRadius: 'counterBorderTopLeftRadius',
				borderBottomLeftRadius: 'counterBorderBottomLeftRadius',
				borderBottomRightRadius: 'counterBorderBottomRightRadius',
			},
		},
		labelStyle: {
			margin: {
				top: 'labelMarginTop',
			},
			text: {
				color: 'labelColor',
				fontFamily: 'labelFontFamily',
				fontWeight: 'labelFontWeight',
				fontSize: 'labelFontSize',
				lineHeight: 'labelLineHeight',
			},
		},
		gap: 'counterGap',
	},
	/* List of properties that have a special assignment way compared to rest of CSS properties.
	   In addition, are not tied to a single block. */
	custom: {
		trackColor: 'trackColor',
		progressColor: 'progressColor',
		progressDir: 'progressDir',
		// property to display global story elements for each individual card (show/hide per card)
		__globalBlockDisplay: '__globalBlockDisplay',
		// a string of CSS properties separated by `;`
		__CSS: '__CSS',
	},
} as const;

/**
 * List of supported other properties, divided into conditional groups
 * These properties are behave identical to "styles"(CSS_PROPS),
 * also each key in group is under influence of the css|state inheritance, but not rendered to DOM automatically.
 * Each corresponding component(BB) uses these properties by his own logic manually
 */
export const OTHER_PROPS = {
	pin: {
		pinType: 'pinType',
	},
	box: {
		boxType: 'boxType',
	},
	sortable: {
		dragContainerOffset: 'dragContainerOffset',
	},
	card: {
		tint: 'cardTint',
		backgroundVideo: 'backgroundVideo',
		backgroundPoster: 'backgroundPoster',
		muted: 'muted',
		loop: 'loop',
	},
	video: {
		videoSrc: 'videoSrc',
		videoPoster: 'videoPoster',
		loop: 'loop',
		muted: 'muted',
		controls: 'controls',
		autoplay: 'autoplay',
	},
	shape: {
		shapeType: 'shapeType',
		lineDir: 'lineDir',
		animateDir: 'animateDir',
	},
	button: {
		buttonShapeType: 'buttonShapeType',
	},
	embed: {
		embedCode: 'embedCode',
	},
	contentLayout: {
		contentHeight: 'contentHeight',
		contentMinHeight: 'contentMinHeight',
		contentAlignY: 'contentAlignY',
		contentMarginTop: 'contentMarginTop',
		contentMarginBottom: 'contentMarginBottom',
		contentMarginLeft: 'contentMarginLeft',
		contentMarginRight: 'contentMarginRight',
	},
	lottie: {
		ltSrc: 'ltSrc',
		ltDir: 'ltDir',
		ltSpeed: 'ltSpeed',
		ltKeepRatio: 'ltKeepRatio',
		ltLoop: 'ltLoop',
		ltTrigger: 'ltTrigger',
		// https://docs.lottiefiles.com/lottie-interactivity/animation-modes#scroll-relative-to-container
		ltContainer: 'ltContainer',
		// https://docs.lottiefiles.com/lottie-interactivity/animation-modes#scroll-with-offset
		ltOffset: 'ltOffset',
	},
	overlay: {
		// overlay component default visibility state
		overlayVisible: 'overlayVisible',
		// overlay component enter/exit animation preset
		overlayAnimationPreset: 'overlayAnimationPreset',
		// overlay component backdrop background
		overlayBackdrop: 'overlayBackdrop',
		// overlay component backdrop display state
		overlayBackdropVisible: 'overlayBackdropVisible',
	},
	counter: {
		counterUnits: 'counterUnits',
		counterDivider: 'counterDivider',
		counterLabels: 'counterLabels',
		counterAnimation: 'counterAnimation',
	},
	slider: {
		loop: 'loop',
		autoplay: 'autoplay',
		autoplayDelay: 'autoplayDelay',
		speed: 'speed',
		spaceBetween: 'spaceBetween',
		slidesPerView: 'slidesPerView',
		sliderDirection: 'sliderDirection',
		sliderMousewheel: 'sliderMousewheel',
		centeredSlides: 'centeredSlides',
		sliderFreeMode: 'sliderFreeMode',
		slideEffect: 'slideEffect',
	},
	sliderPagination: {
		paginationType: 'paginationType',
	},
} as const;

/**
 * These are the properties which placed in building block at "componentProps|editorProps"
 * path, and they are NOT depends on state or media query and also without inheritance. Assigned once for all cases.
 */
export const UNIFORM_PROPS = {
	shareType: 'shareType',
	// Destination to where redirect user when vising shared link
	shareTarget: 'shareTarget',
	// Optional URL to redirect users if `shareTarget` is `ShareTarget.custom`
	shareCustomUrl: 'shareCustomUrl',
	// Source of Open Graph data for sharing
	shareOgSource: 'shareOgSource',
	// Timeout duration moving to the next card after answer submission
	answerTimeout: 'answerTimeout',
	// URL to navigate to
	btnLink: 'btnLink',
	// Story Card ID to navigate to
	btnNavLink: 'btnNavLink',
	// Building BLock Node ID to scroll into view
	btnBlockLink: 'btnBlockLink',
	// Flag to display in the selectable block list for btnBlockLink
	blockLinkTarget: 'blockLinkTarget',
	// Flag for link target attribute value (_blank or _self)
	btnTarget: 'btnTarget',
	btnSubmit: 'btnSubmit',
	fieldPlaceholder: 'fieldPlaceholder',
	// validation: field value is required
	fieldRequired: 'fieldRequired',
	// validation: field value should be in full name format
	fieldFullName: 'fieldFullName',
	// one of FieldBBType
	fieldType: 'fieldType',
	// A record of error messages for each error type (Record<FieldBBErrorType, string>)
	fieldError: 'fieldError',
	// name of the error to preview in card editor
	fieldErrorShow: 'fieldErrorShow',
	onSwipe: 'onSwipe',
	tag: 'tag',
	title: 'title',
	timerTime: 'timerTime',
	timerType: 'timerType',
	answerProgressType: 'answerProgressType',
	// card enter/out transition
	expose: 'expose',
	duration: 'duration', // expose.duration
	effect: 'effect', // expose.effect
	alt: 'alt',
	// date picker field format
	dpFormat: 'dpFormat',
	// date picker field delimiter
	dpDelimiter: 'dpDelimiter',
	// `PhoneInput` default country in iso2 format
	telCountry: 'telCountry',
	// enum BBMetaType
	metaType: 'metaType',
	// array containing options for the Select component
	selectOptions: 'selectOptions',
	// order of `selectOptions` list
	selectOptionsOrder: 'selectOptionsOrder',
	// indicates whether the Select component functions as a multi-select
	selectIsMulti: 'selectIsMulti',
	// overlay component type: Fixed(modal, toast), Relative(tooltip, dropdown)
	overlayType: 'overlayType',
	// overlay component show/hide action (tap or hover)
	overlayShowOn: 'overlayShowOn',
	// overlay component show/hide triggers
	overlayTriggers: 'overlayTriggers',
	// comma-separated indices representing initially displayed items of SortableBox component
	sortableStartItems: 'sortableStartItems',
	// number of seconds to wait before moving to next card after SortableBox component finishes sorting
	sortableNavTimeout: 'sortableNavTimeout',
	// custom order of SortableBox items:
	sortableOrder: 'sortableOrder',
	// sorting mode of SortableBox component ('custom' or 'random'):
	sortableMode: 'sortableMode',
	// counter component type: 'count-up' or 'count-down':
	counterType: 'counterType',
	// counter component date:
	counterDate: 'counterDate',
	// slider arrow direction prev|next
	sliderArrowDir: 'sliderArrowDir',
	// cms collection reference
	collectionId: 'collectionId',
} as const;
